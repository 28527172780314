import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { closeIcon, eyeSlashIcon } from '../../assets/Icons';
import changePassword from '../../api/changePassword';
import { useSnackbar } from '../CommonComponents/Snackbar/index';

const ChangePasswordModal = ({ show, handleClose }) => {
    const snackbar = useSnackbar();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    return (
        <Modal show={show} onHide={handleClose} backdrop={true} centered>
            <Modal.Header className="d-flex justify-content-between">
                <div>
                    <Modal.Title>Change Password</Modal.Title>
                    <span>{localStorage.getItem('email')}</span>
                </div>
                <Button variant="link" onClick={handleClose} className="p-0">
                    <img src={closeIcon} alt="Close Icon" />
                </Button>
            </Modal.Header>
            <Formik
                initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
                onSubmit={(values) => {
                    changePassword(values).then((res) => {
                        if (res?.data?.success) {
                            snackbar(res?.data?.message, 'success');
                            handleClose();
                        }
                        else {
                            snackbar(res?.message, 'error');
                        }
                    });
                }}
            >
                {({ handleSubmit, errors, touched }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="formOldPassword">
                                <Form.Label style={{ color: '#475569' }}>
                                    Old Password <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <div style={{ position: 'relative' }}>
                                    <Field
                                        style={{
                                            height: '46px',
                                            padding: '10px',
                                            border: 'solid 1px #CBD5E1',
                                            width: '100%',
                                        }}
                                        name="oldPassword"
                                        type={showOldPassword ? 'text' : 'password'}
                                        as={Form.Control}
                                        placeholder="Enter old password"
                                        isInvalid={touched.oldPassword && errors.oldPassword}
                                    />
                                    <img
                                        src={eyeSlashIcon}
                                        alt="Toggle Visibility"
                                        onClick={() => setShowOldPassword(!showOldPassword)}
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.oldPassword}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formNewPassword">
                                <Form.Label>
                                    New Password <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <div style={{ position: 'relative' }}>
                                    <Field
                                        style={{
                                            height: '46px',
                                            padding: '10px',
                                            border: 'solid 1px #CBD5E1',
                                            width: '100%',
                                        }}
                                        name="newPassword"
                                        type={showNewPassword ? 'text' : 'password'}
                                        as={Form.Control}
                                        placeholder="Enter new password"
                                        isInvalid={touched.newPassword && errors.newPassword}
                                    />
                                    <img
                                        src={eyeSlashIcon}
                                        alt="Toggle Visibility"
                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.newPassword}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formConfirmPassword">
                                <Form.Label>
                                    Confirm Password <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <div style={{ position: 'relative' }}>
                                    <Field
                                        style={{
                                            height: '46px',
                                            padding: '10px',
                                            border: 'solid 1px #CBD5E1',
                                            width: '100%',
                                        }}
                                        name="confirmPassword"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        as={Form.Control}
                                        placeholder="Confirm new password"
                                        isInvalid={touched.confirmPassword && errors.confirmPassword}
                                    />
                                    <img
                                        src={eyeSlashIcon}
                                        alt="Toggle Visibility"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.confirmPassword}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                                style={{
                                    borderColor: 'black',
                                    color: 'black',
                                    background: 'white',
                                    borderWidth: '1px',
                                    width: '8rem',
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="warning"
                                style={{
                                    color: 'white',
                                    background: '#F56F10',
                                    borderWidth: '1px',
                                    width: '8rem',
                                }}
                            >
                                Continue
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ChangePasswordModal;
