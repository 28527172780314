import React, { useState } from 'react';
import Navbar from '../components/CommonComponents/Navbar';
import Sidebar from '../components/CommonComponents/Sidebar';
import './layout.css';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorComponent from '../views/error';

const Layout = () => {
  const [activePage, setActivePage] = useState('Dashboard');
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);

  const handleSetShowCustomerDetails = (value) => {
    setShowCustomerDetails(value);
  };

  return (
    <div className="layout-container">
      <Sidebar setActivePage={setActivePage} handleSetShowCustomerDetails={handleSetShowCustomerDetails} />
      <div className="content-container">
        <Navbar activePage={activePage} handleSetShowCustomerDetails={handleSetShowCustomerDetails} />
        <main className="main-content">
          <ErrorBoundary fallback={<ErrorComponent/>}>
            <Outlet context={{ showCustomerDetails, setShowCustomerDetails: handleSetShowCustomerDetails }} />
          </ErrorBoundary>
        </main>
      </div>
    </div>
  );
};

export default Layout;
