import React, { Component } from 'react';

class ErrorComponent extends Component {
	render() {
		return (
			<div
				style={{
					textAlign: 'center',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
				}}
			>
				<strong>
					<p>We're sorry, but something went wrong.</p>
				</strong>
				{/* <hr /> */}
				<p>Please try again later.</p>
			</div>
		);
	}
}

export default ErrorComponent;
