import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import { notificationIcon, key, logout } from '../../../assets/Icons';
import ChangePasswordModal from '../../../components/ChangePasswordModal';
import { useNavigate, useParams } from 'react-router-dom';
import useZustandStore from '../../../store';
import './navbar.css'

const CustomNavbar = ({ activePage, handleSetShowCustomerDetails }) => {
	const [bread, setBread]=useState(false)
	const role = localStorage.getItem('role');
	const name = localStorage.getItem('name');
    const { id } = useParams();
	
	const navigate = useNavigate();

	const customerName = useZustandStore((state) => state.customerName);
	const insuranceActiveTab = useZustandStore((state) => state.insuranceActiveTab);


	const [visibility, setVisibility] = useState({
		showSignOut: false,
		showNotification: false,
		showChangePassword: false,
	});

	const signOutRef = useRef(null);
	const notificationRef = useRef(null);

	const toggleVisibility = (key) => {
		setVisibility((prev) => ({ ...prev, [key]: !prev[key] }));
	};

	const handleClickOutside = (event) => {
		if (signOutRef.current && !signOutRef.current.contains(event.target)) {
			setVisibility((prev) => ({ ...prev, showSignOut: false }));
		}
		if (notificationRef.current && !notificationRef.current.contains(event.target)) {
			setVisibility((prev) => ({ ...prev, showNotification: false }));
		}
	};

	const handleSignOut = () => {
		localStorage.removeItem('token');
		navigate('/');
	};

	const handleOnClick = () => {
		if (activePage === 'Customers') {
			handleSetShowCustomerDetails(false);
			navigate('/customers')
		}
		if (activePage === 'Insurances') {
			navigate('/insurances')
		}
	};

	function setBreadcrumbs(){
		const breadcrumbElement = document.getElementById("active-page");
		if (breadcrumbElement) {
			breadcrumbElement.style.color = '#94A3B8';
		}
	}

	function resetBreadcrumbs(){
		 document.getElementById("active-page").style.color='black';
		 document.getElementById("active-page").style.textDecoration='none'			
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	useEffect(() => {
		if (id) {
			setBread(true);
		} else {
			setBread(false);
		}
	}, [activePage, customerName, id]);
	
	useEffect(() => {
		if (bread) {
			setBreadcrumbs(); 
		}

		else{
			resetBreadcrumbs();
		}
	}, [bread]);
	

	return (
		<Navbar className="custom-border p-1 d-flex justify-content-between">
			<Container>
				<Navbar.Brand className="navbar-brand-custom">
					<span onClick={handleOnClick} style={{cursor:'pointer', color:'#94A3B8'}} id="active-page">{activePage}</span>
					{activePage === 'Customers' && customerName && id && (
						<span className="customer-name">/{customerName}</span>
					)} 
					{activePage === 'Insurances' && insuranceActiveTab && id && (
						<>
						<span style={{cursor:'pointer', color:'#94A3B8'}} onClick={(e)=>{
							if(e) navigate('/insurances')
						}} > / {insuranceActiveTab}</span>
						<span className="customer-name"> / {id}</span>
						</>
					)}      
				</Navbar.Brand>
				<Nav className="d-flex align-items-center">
				{/* TODO: To implement notification later */}
					{/* <Nav.Link ref={notificationRef} onClick={() => toggleVisibility('showNotification')}>
						<img src={notificationIcon} alt="Notifications" />
						{visibility.showNotification && (
							<Dropdown align="end">
								<Dropdown.Menu
									show
									style={{ maxHeight: '600px', overflowY: 'auto', padding: '0.5rem' }}
								>
									<div className="d-flex justify-content-between align-items-center p-2">
										<h5 className="mb-0" style={{ fontSize: '1.5rem' }}>
											Notifications
										</h5>
										<Dropdown.Item className="p-0" style={{ width: 'auto' }}>
											<Nav.Link
												variant="link"
												style={{ fontWeight: 'bold', color: '#F56F10', fontSize: '0.875rem' }}
											>
												Mark all as read
											</Nav.Link>
										</Dropdown.Item>
									</div>
									<Dropdown.Divider />

									<Dropdown.Item
										className="text-truncate"
										style={{ padding: '0.5rem 1rem', fontSize: '0.875rem' }}
									>
										<div className="d-flex flex-column">
											<div>
												<span className="fw-bold">Aditya Patel</span> submitted a claim for{' '}
												<span className="fw-bold">Oriental Insurance</span>.
											</div>
										</div>
										<p>5h ago</p>
									</Dropdown.Item>
									<Dropdown.Item
										className="text-truncate"
										style={{ padding: '0.5rem 1rem', fontSize: '0.875rem' }}
									>
										<div className="d-flex flex-column">
											<div>
												<span className="fw-bold">Aditya Patel</span> submitted a claim for{' '}
												<span className="fw-bold">Oriental Insurance</span>.
											</div>
										</div>
										<p>5h ago</p>
									</Dropdown.Item>

									<Dropdown.Item
										className="text-truncate"
										style={{ padding: '0.5rem 1rem', fontSize: '0.875rem' }}
									>
										<div className="d-flex flex-column">
											<div>
												<span className="fw-bold">Aditya Patel</span> submitted a claim for{' '}
												<span className="fw-bold">Oriental Insurance</span>.
											</div>
										</div>
										<p>5h ago</p>
									</Dropdown.Item>

									<Dropdown.Item
										className="text-truncate"
										style={{ padding: '0.5rem 1rem', fontSize: '0.875rem' }}
									>
										<div className="d-flex flex-column">
											<div>
												<span className="fw-bold">Aditya Patel</span> submitted a claim for{' '}
												<span className="fw-bold">Oriental Insurance</span>.
											</div>
										</div>
										<p>5h ago</p>
									</Dropdown.Item>

									<Dropdown.Item
										className="text-truncate"
										style={{ padding: '0.5rem 1rem', fontSize: '0.875rem' }}
									>
										<div className="d-flex flex-column">
											<div>
												<span className="fw-bold">Aditya Patel</span> submitted a claim for{' '}
												<span className="fw-bold">Oriental Insurance</span>.
											</div>
										</div>
										<p>5h ago</p>
									</Dropdown.Item>

									<Dropdown.Item
										className="text-truncate"
										style={{ padding: '0.5rem 1rem', fontSize: '0.875rem' }}
									>
										<div className="d-flex flex-column">
											<div>
												<span className="fw-bold">Aditya Patel</span> submitted a claim for{' '}
												<span className="fw-bold">Oriental Insurance</span>.
											</div>
										</div>
										<p>5h ago</p>
									</Dropdown.Item>
									<Dropdown.Divider />
								</Dropdown.Menu>
							</Dropdown>
						)}
					</Nav.Link> */}

					<Nav.Link ref={signOutRef} onClick={() => toggleVisibility('showSignOut')}>
						<div className="border-auto rounded d-flex gap-2">
							<div style={{ borderLeft: '1px solid #E2E8F0' }}></div>
							<div
								className="p-2 mb-3 fw-bold border rounded"
								style={{ background: '#FEF0E7', color: '#F7893B' }}
							>
								{name.split(" ").map(word => word[0].toUpperCase()).join("")}
							</div>
							<div>
								<span className="fw-bold">{name}</span>
								<p>{role}</p>
								<Dropdown show={visibility.showSignOut}>
									<Dropdown.Menu align="end" style={{ top: '100%', right: '0', borderRadius: '4px' }}>
										<Dropdown.Item
											onClick={() => toggleVisibility('showChangePassword')}
											className="d-flex align-items-center gap-2"
											style={{
												outline: 'none',
												backgroundColor: 'transparent',
												color: 'inherit',
											}}
											onMouseDown={(e) => e.preventDefault()}
										>
											<img src={key} alt="Change Password Icon" />
											<span>Change Password</span>
										</Dropdown.Item>

										<Dropdown.Item
											onClick={handleSignOut}
											className="d-flex align-items-center gap-2"
											style={{
												color: '#F43F5E',
												outline: 'none',
												backgroundColor: 'transparent',
											}}
											onMouseDown={(e) => e.preventDefault()}
										>
											<img src={logout} alt="Sign Out Icon" />
											<span>Sign Out</span>
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</Nav.Link>
				</Nav>
			</Container>

			<ChangePasswordModal
				show={visibility.showChangePassword}
				handleClose={() => toggleVisibility('showChangePassword')}
			/>
		</Navbar>
	);
};

export default CustomNavbar;
