import create from 'zustand';

const useZustandStore = create((set) => ({
    customerName: '',
    setCustomerName: (name) => set({ customerName: name }),
    //Claims
    claimsFilters : {},
    setClaimsFilters: (filters) => set({ claimsFilters: filters }),
    // Payment
    paymentsFilters : {},
    setPaymentsFilters: (filters) => set({ paymentsFilters: filters }),
    // Insurance
    insuranceActiveIndex:0,
    insuranceActiveTab:'',
    insuranceSearchInput:'',
    setInsuranceSearchInput: (input) => set({ insuranceSearchInput: input }),
    setInsuranceActiveIndex: (index) => set({ insuranceActiveIndex: index}),
    setInsuranceActiveTab: (tab,index) => set({ insuranceActiveTab: tab,insuranceActiveIndex: index,requestedInsuranceFilter:{}, acceptedInsuranceFilter:{}}),
    otherInsuranceDetails:{},
    setOtherInsuranceDetails: (details) => set({ otherInsuranceDetails: details }),
    requestedInsuranceFilter: {},
    setRequestedInsuranceFilter: (filters) => set({ requestedInsuranceFilter: filters }),
    acceptedInsuranceFilter: {},
    setAcceptedInsuranceFilter: (filters) => set({ acceptedInsuranceFilter: filters }),
    showInsuranceClearFilter: false,
    toggleInsuranceClearFilter: (bool)=> set({ showInsuranceClearFilter: bool}),
    clearInsuranceFilter: () => set({ acceptedInsuranceFilter:{}, rejectedInsuranceFilter:{} })
    // Payment

}));

export default useZustandStore;