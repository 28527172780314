import React, { createContext, useContext, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

const SnackbarContext = createContext();

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }) => {
  const [snackbars, setSnackbars] = useState([]);

  const showSnackbar = (message, severity = 'success') => {
    const newSnackbar = { message, severity, key: new Date().getTime() };
    setSnackbars((prev) => [...prev, newSnackbar]);
  };

  const handleClose = (key) => {
    setSnackbars((prev) => prev.filter((snackbar) => snackbar.key !== key));
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      {snackbars.map((snackbar) => (
        <Snackbar
          key={snackbar.key}
          open
          autoHideDuration={3000}
          onClose={() => handleClose(snackbar.key)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{
            width: {
              xs: '90%',
              sm: '400px',
            },
          }}
        >
          <Alert
            onClose={() => handleClose(snackbar.key)}
            severity={snackbar.severity}
            sx={{
              width: '100%',
              padding: '16px',
              fontSize: {
                xs: '0.875rem',
                sm: '1rem',
              },
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      ))}
    </SnackbarContext.Provider>
  );
};
