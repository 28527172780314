import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../../assets/Images/logo.png';
import './Sidebar.css';
import { Pathname } from '../../../pathname';
import { dashboardIcon, usersIcon, claimsIcon, paymentsIcon, customerIcon, insurancesIcon, notificationIcon, helpsupportIcon, acticeDashboard, activeUsers, activePayments, activeInsurances, activeCustomers, activeClaims, activeNotification, activeHelpSupport, } from '../../../assets/Icons';

export const Sidebar = ({ setActivePage, handleSetShowCustomerDetails }) => {
	const [activeLink, setActiveLink] = useState('');
	const location = useLocation();

	const pathSegments = location.pathname.split('/');
	const parentPath = pathSegments[1] || '';

	const formattedPath = parentPath.charAt(0).toUpperCase() + parentPath.slice(1).toLowerCase();

	const handleLinkClick = (link) => {
		const hideCustomerDetailsLinks = [
			'Insurances',
			'Dashboard',
			'Users',
			'Payments',
			'Claims',
			'Notification',
			'Support Requests'
		];

		if (hideCustomerDetailsLinks.includes(link)) {
			handleSetShowCustomerDetails(false);
		}

		if (link === 'Help-support') {
			setActivePage('Support Requests');
			setActiveLink('Support Requests');
		} else {
			setActivePage(link);
			setActiveLink(link);
		}
	};

	const navigationLinks = [
		{
			title: 'Dashboard',
			icons: { active: acticeDashboard, inactive: dashboardIcon },
			path: Pathname.DASHBOARD
		},
		{
			title: 'Users',
			icons: { active: activeUsers, inactive: usersIcon },
			path: Pathname.USERS
		},
		{
			title: 'Payments',
			icons: { active: activePayments, inactive: paymentsIcon },
			path: Pathname.PAYMENTS
		},
		{
			title: 'Insurances',
			icons: { active: activeInsurances, inactive: insurancesIcon },
			path: Pathname.INSURANCES
		},
		{
			title: 'Customers',
			icons: { active: activeCustomers, inactive: customerIcon },
			path: Pathname.CUSTOMERS
		},
		{
			title: 'Claims',
			icons: { active: activeClaims, inactive: claimsIcon },
			path: Pathname.CLAIMS
		},
		{
			title: 'Notification',
			icons: { active: activeNotification, inactive: notificationIcon },
			path: Pathname.NOTIFICATION
		},
		{
			title: 'Support Requests',
			icons: { active: activeHelpSupport, inactive: helpsupportIcon },
			path: Pathname.HELPANDSUPPORT
		}
	]
	const userRole = localStorage.getItem('role');
	const roleBasedHiddenLinks = {
		FINANCE: ['Users', 'Customers', 'Claims', 'Notification'],
		SUPPORT: ['Users', 'Customers', 'Payments', 'Notification'],
		OPERATION: ['Payments'],
	};
	const filterNavigationLinks = (role) => {
		if (role === 'ADMIN')
			return navigationLinks;
		const hiddenTitles = roleBasedHiddenLinks[role] || [];

		return navigationLinks.filter((navItem) => !hiddenTitles.includes(navItem.title));
	};
	const filteredNavigationLinks = filterNavigationLinks(userRole);

	useEffect(() => {
		handleLinkClick(formattedPath);
	}, [formattedPath]);

	return (
		<div className="sidebar border d-flex flex-column align-items-center pb-2">
			<div className="logo-section d-flex align-items-center">
				<img src={logo} alt="Logo" className="logo-image ms-2" />
				<span className="logo-text ms-2">Shield Insurance</span>
			</div>

			<nav className="nav flex-column mt-2">
				{
					filteredNavigationLinks?.map((navItem,i) => (
						<NavLink
						    key={i}
							to={navItem.path}
							className="nav-item"
							onClick={() => handleLinkClick(navItem.title)}
							style={{
								backgroundColor: activeLink === navItem.title ? 'white' : 'transparent',
								border: activeLink === navItem.title ? '0.5px solid #FBC49D' : 'initial',
								borderRadius: activeLink === navItem.title ? '8px' : 'initial',
								textDecoration: 'none',
							}}
						>
							<img src={activeLink === navItem.title ? navItem.icons.active : navItem.icons.inactive} alt="Dashboard Icon" />
							<div
								className="nav-link"
								style={{
									border: '#FBC49D',
									color: activeLink === navItem.title ? '#F56F10' : 'initial',
									fontWeight: activeLink === navItem.title ? 'bold' : 'normal',
								}}
							>
								{navItem.title}
							</div>
						</NavLink>
					))
				}

				{/* <NavLink
					className={`nav-item ${activeLink === 'Users' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Users')}
					style={{
						backgroundColor: activeLink === 'Users' ? 'white' : 'transparent',
						border: activeLink === 'Users' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Users' ? '8px' : 'initial',
						textDecoration: 'none',
					}}
					to={Pathname.USERS}
				>
					<img src={activeLink === 'Users' ? activeUsers : usersIcon} alt="Users Icon" />
					<div
						className="nav-link"
						style={{
							color: activeLink === 'Users' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Users' ? 'bold' : 'normal',
						}}
					>
						Users
					</div>
				</NavLink>

				<NavLink
					to={Pathname.PAYMENTS}
					className={`nav-item ${activeLink === 'Payments' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Payments')}
					style={{
						backgroundColor: activeLink === 'Payments' ? 'white' : 'transparent',
						border: activeLink === 'Payments' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Payments' ? '8px' : 'initial',
						textDecoration: 'none',
					}}
				>
					<img src={activeLink === 'Payments' ? activePayments : paymentsIcon} alt="Payments Icon" />
					<div
						className="nav-link"
						style={{
							color: activeLink === 'Payments' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Payments' ? 'bold' : 'normal',
						}}
					>
						Payments
					</div>
				</NavLink>

				<NavLink
					to={Pathname.INSURANCES}
					className={`nav-item ${activeLink === 'Insurances' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Insurances')}
					style={{
						backgroundColor: activeLink === 'Insurances' ? 'white' : 'transparent',
						border: activeLink === 'Insurances' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Insurances' ? '8px' : 'initial',
						textDecoration: 'none',
					}}
				>
					<img src={activeLink === 'Insurances' ? activeInsurances : insurancesIcon} alt="Insurances Icon" />
					<div
						className="nav-link"
						style={{
							color: activeLink === 'Insurances' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Insurances' ? 'bold' : 'normal',
						}}
					>
						Insurances
					</div>
				</NavLink>

				<NavLink
					to={Pathname.CUSTOMERS}
					className={`nav-item ${activeLink === 'Customers' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Customers')}
					style={{
						backgroundColor: activeLink === 'Customers' ? 'white' : 'transparent',
						border: activeLink === 'Customers' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Customers' ? '8px' : 'initial',
						textDecoration: 'none',
					}}
				>
					<img src={activeLink === 'Customers' ? activeCustomers : customerIcon} alt="Customers Icon" />
					<div
						className="nav-link"
						style={{
							color: activeLink === 'Customers' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Customers' ? 'bold' : 'normal',
						}}
					>
						Customers
					</div>
				</NavLink>

				<NavLink
					to={Pathname.CLAIMS}
					className={`nav-item ${activeLink === 'Claims' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Claims')}
					style={{
						backgroundColor: activeLink === 'Claims' ? 'white' : 'transparent',
						border: activeLink === 'Claims' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Claims' ? '8px' : 'initial',
						textDecoration: 'none',
					}}
				>
					<img src={activeLink === 'Claims' ? activeClaims : claimsIcon} alt="Claims Icon" />
					<div
						className="nav-link"
						style={{
							color: activeLink === 'Claims' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Claims' ? 'bold' : 'normal',
						}}
					>
						Claims
					</div>
				</NavLink>

				<NavLink
					to={Pathname.NOTIFICATION}
					className={`nav-item ${activeLink === 'Notification' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Notification')}
					style={{
						backgroundColor: activeLink === 'Notification' ? 'white' : 'transparent',
						border: activeLink === 'Notification' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Notification' ? '8px' : 'initial',
						textDecoration: 'none',
					}}
				>
					<img
						src={activeLink === 'Notification' ? activeNotification : notificationIcon}
						alt="Notification Icon"
					/>
					<div
						className="nav-link"
						style={{
							color: activeLink === 'Notification' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Notification' ? 'bold' : 'normal',
						}}
					>
						Notification
					</div>
				</NavLink>

				<NavLink
					to={Pathname.HELPANDSUPPORT}
					className={`nav-item ${activeLink === 'Support Requests' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Support Requests')}
					style={{
						backgroundColor: activeLink === 'Support Requests' ? 'white' : 'transparent',
						border: activeLink === 'Support Requests' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Support Requests' ? '8px' : 'initial',
						textDecoration: 'none',
					}}
				>
					<img
						src={activeLink === 'Support Requests' ? activeHelpSupport : helpsupportIcon}
						alt="Support Requests Icon"
					/>
					<div
						className="nav-link"
						style={{
							color: activeLink === 'Support Requests' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Support Requests' ? 'bold' : 'normal',
						}}
					>
						Support Requests
					</div>
				</NavLink> */}
			</nav>
		</div>
	);
};

export default Sidebar;
