import axios from 'axios';
import { API_BASE_URL, API_COMMON_PATH } from './apiConfig';
import { useSnackbar } from '../components/CommonComponents/Snackbar';
import { useEffect } from 'react';

const axiosInstance = axios.create({
	baseURL: API_BASE_URL + API_COMMON_PATH,
	// timeout: 30000,
	headers: { 'Content-Type': 'application/json' },
	// validateStatus: (status) => status >= 200 && status < 300,
	// withCredentials: true,
});

axiosInstance.interceptors.request.use(
	(config) => {
		const accessToken = localStorage.getItem('token');
		if (accessToken) {
			config.headers['Authorization'] = accessToken;
		}
		return config;
	},
	(error) => Promise.reject(error)
);

const AxiosInterceptor = ({ children }) => {
	const snackbar = useSnackbar();

	useEffect(() => {
		const responseInterceptor = (response) => {
			return response;
		};

		const errorInterceptor = (error) => {
			const originalRequest = error.config;
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				const rToken = localStorage.getItem('refreshToken');
				axios
					.get(`${API_BASE_URL}${API_COMMON_PATH}/refresh-token`, {
						params: {
							refreshToken: rToken,
						},
					})
					.then((response) => response.data?.data)
					.then((data) => {
						localStorage.setItem('token', data?.accessToken);
						localStorage.setItem('refreshToken', data?.refreshToken);
						axiosInstance.defaults.headers.common['Authorization'] = data?.accessToken;
					})
					.catch((error) => {
						snackbar('Session expired.', 'error');
						localStorage.removeItem('token');
						localStorage.removeItem('refreshToken');
						window.location.href = '/';
					});

				return axiosInstance(originalRequest);
			}
			return Promise.reject(error);
		};

		const interceptor = axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

		return () => axiosInstance.interceptors.response.eject(interceptor);
	}, [window.location.pathname]);

	return children;
};

export default axiosInstance;
export { AxiosInterceptor };
