import axios from '../config/middleware'

const changePassword = async (changePasswordData) => {
    try {
        const response = await axios.post(`/change-password/${localStorage.getItem('id')}`, changePasswordData);
        if (response.data) {
            return { success: true, data: response.data };
        }
        return { success: false, message: 'Password not changed' };
    } catch (error) {
        return { success: false, message: error.response?.data?.message || 'Failed to change Password' };
    }
};

export default changePassword;
